/**
 * Home page for Venture landing.
 *
 *  @ Aaron Baw 2018
 */

import React, { Component } from 'react';
import { LogoWithText, LogoGlyph } from './Logo.js';
import HamburgerMenu from './HamburgerMenu.js';
import Typed from 'react-typed';

class Navigation extends Component {
  render(){
    return (
      <nav style={{
        'display': 'flex',
        alignItems: 'center',
        position: 'absolute',
        top: '0px',
        width: '100%',
        height: '400px',
        overflow: 'hidden'
      }}>

        <LogoWithText style={{
          position: 'absolute',
          top: '35px',
          left: '42px',
        }} height={'25px'} width={'130px'} />


          <HamburgerMenu
          scrollToRefs={this.props.scrollToRefs}
          style={{
            'margin-left': '40px',
            'margin-bottom': '16px'
          }}/>
      </nav>
    );
  }
}

class DynamicText extends Component {
  render(){

    return (<div className="dynamic-text-container">
      <p>
        {this.props.children}
        <mark style={{
          backgroundColor: 'rgba(0,0,0,0)',
          color: '#84a3fa'
        }}>
          <Typed
            strings={this.props.append}
            typeSpeed={75}
            backSpeed={40}
            loop={true}
          />
        </mark>
      </p>


    </div>);

    // return this.props.lines.map(line => {
    //   line = line.split('*');
    //   if (line.length == 1) return <p>{line}</p>
    //   return (<p>
    //     {line[0]}
    //       <mark>{line[1]}</mark>
    //     {line[2]}
    //   </p>);
    // }
    // )



  };

}

class HeroContent extends Component {
  render(){
    return (
      <div className="hero-content-container">
        <LogoGlyph style={{
          height: '135px',
          marginTop: '30px'
        }}/>

        <div className="hero-content-text-container">

          <DynamicText
            append={[
              "a website.",
              "branding.",
              "an app.",
              "gradients."
            ]}
          >I need </DynamicText>

          {/* Static subheading text */}
          <h2 style={{
            fontFamily: 'proxima-soft, sans-serif',
            color: 'rgba(226, 232, 242, 0.5)',
            fontWeight: '400',
            lineHeight: '1',
            margin: '0'
          }}>
            Venture is a software lab which cares deeply about experiences.
          </h2>

        </div>

      </div>
    );
  }
}


export default class HomePage extends Component {

  render(){
    return (
      <div
      ref={this.props.scrollToRefs[0]}
      className="homepage-container">

        <Navigation scrollToRefs={this.props.scrollToRefs}/>

        <HeroContent />

        {/* Background wavy graphics */}
        <svg width={7692} height={2787} viewBox={"0 0 7692 2787"} className="hero-background-graphic-one">
          <path
            fillRule="evenodd"
            strokeWidth={11}
            stroke="#FFF"
            opacity={0.471}
            fill="#181818"
            d="M240.5 950.5c518.942-183.17 1414.159-932 2586-932s717.91 1642 3486 1642 456 1043 456 1043l-6493 65s-553.942-1634.83-35-1818z"
          />
        </svg>

        <svg height="200vh" width="200vw" viewBox="0 0 7551 2787" className="hero-background-graphic-two">
        	<path
            d="M240.500,950.500 C759.442,767.330 1654.659,18.500 2826.500,18.500 C3998.341,18.500 3544.410,1660.500 6312.500,1660.500 C9080.590,1660.500 6270.500,2677.500 6270.500,2677.500 L275.500,2768.500 C275.500,2768.500 -278.442,1133.670 240.500,950.500 Z"
            fill="rgb(24, 24, 24)"
            stroke="rgb(255, 255, 255)"
            strokeWidth="11px"
          />
        </svg>


      </div>

    );
  }
}

// Utility functions.
function log(...msg){
  console.log(`HOME |`, ...msg);
}
