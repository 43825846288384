/**
 * Previous work page.
 */

import React, { Component } from 'react';
import { PortfolioItem } from './Portfolio.js';

class ContentGrid extends Component {

  render(){
    return (
      <div className="grid">
        {this.props.items.map((item, i) =>
          <PortfolioItem
            key={i}
            link={item.link}
            name={item.name}
            caption={item.caption}
            backgroundColor={item.backgroundColor}
            graphic={item.graphic}
          />
        )}
      </div>
    );
  }

}

class OurBabiesPage extends Component {


  render(){
    return (
      <div
        ref={this.props.scrollRef}
        style={{
        height: '100vh',
        width: '100vw',
        display: 'flex',
        alignItems: 'center',
        flexDirection: 'column'
      }} className="our-babies-page-container">

      <div style={{
      width: '80%',
      height: 'min-content',
      marginTop: '50px',
      marginBottom: '50px',
      }} className="our-babies-heading-container">
      <h1 style={{
        fontFamily: '"Europa", sans-serif',
        fontSize: '60px',
        fontWeight: 'Bold',
        color: '#181818',
        margin: '0',
      }}>Our Babies</h1>

      <h2 style={{
        fontFamily: '"Europa", sans-serif',
        fontSize: '20px',
        color: '#7b7b7b',
        margin: '0',
        marginTop: '15px'
      }}>
        Every project we take on is laced with thought, care, and love.
      </h2>
      </div>

      <ContentGrid items={[
        // {
        //   name: 'LocusTrack',
        //   caption: "A minimalistic pomodoro timer with Trello integration.",
        //   link: 'https://locustrack.herokuapp.com/',
        //   backgroundColor: '#7accc8'
        // },
        ,
        {
          name: "36 North",
          caption: 'Site design, implementation (React.js) and branding. Border intelligence and software for companies looking to go beyond.',
          link: 'http://36north.io',
          backgroundColor: '#26C6DA'
        },
        {
          name: 'Portfolio Site',
          caption: "Aaron Baw's personal portfolio site, designed & implemented from scratch, with love.",
          link: 'http://aaronbaw.com/',
          backgroundColor: '#fbaf5d'
        },
        {
          name: 'SurfTheMusic',
          caption: "Audio-visual content curation brand, promoting the best of up and coming indie electronic artists.",
          link: "https://youtube.com/c/surfthemusic",
          backgroundColor: '#7cc576'
        }
      ]}/>

      </div>
    );
  }
}

export default OurBabiesPage;
