/**
 * Venture Footer.
 *
 * @ Aaron Baw 2018
 */

import React, { Component } from 'react';
import { LogoGlyph } from './Logo.js';

class Footer extends Component {
  render(){
    return (

      <footer style={{
        marginTop: 'auto',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        padding: '35px',
        zIndex: '2'
      }}>
        <LogoGlyph viewBox={"-15 -10 558.77 545.5"} style={{
          height: '25px',
        }}
        pathStyle={{
          stroke: '#c0c4cc',
          fill: '#ffffff00',
          strokeWidth: '20px'
        }} />

      </footer>

    );
  }
}

export default Footer;
