import React, { Component } from 'react';
import TypeKit from 'react-typekit';
import HomePage from './Home.js'
import OurBabiesPage from './OurBabies.js';
import ContactPage from './Contact.js';
import './App.css';

class App extends Component {

  constructor(props, context){
    super(props, context);
    // We pass the references to the scroll handlers before the components are defined
    // so we make use of React's Forward referencing to achieve this. (Create a reference
    // and force component to use it.)
    this.scrollToRefs = [React.createRef(), React.createRef(), React.createRef()];

  }

  render() {
    return (
      <div className="App">
      <TypeKit kitId="phi0cod" />

      <HomePage scrollToRefs={this.scrollToRefs} />

      <OurBabiesPage scrollRef={this.scrollToRefs[1]} />

      <ContactPage scrollRef={this.scrollToRefs[2]} />

      </div>
    );
  }
}

export default App;
