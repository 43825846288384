/**
 * Contact Page.
 *
 * @ Aaron Baw 2018
 */

import React, { Component } from 'react';
import Footer from './Footer.js';
import ReactCSSTransitionGroup from 'react-addons-css-transition-group'; // ES6


class Input extends Component {

  constructor(props, context){
    super(props, context);

    this.state = {
      charsRemaining: this.props.maxLength
    };

    this.onInputChange = this.onInputChange.bind(this);

  }

  componentDidMount(){
    if (this.props.autoFocus) this.ref.focus();
  }

  onInputChange(e){

    this.setState({
      ...this.state,
      charsRemaining: this.props.maxLength - e.target.value.length
    }, () => this.props.onInputChange ? this.props.onInputChange(this.state.charsRemaining) : null);

  }

  render(){
    return (
      <div style={{
        display: 'flex',
        flexDirection: 'column',
        ...this.props.style
      }} className="idea-input-container">

      <input
        ref={ref => this.ref = ref}
        onChange={this.onInputChange}
        onKeyUp={e => e.key == "Enter" ? this.props.onEnter && this.props.onEnter(e.target.value) : null}
        maxLength={this.props.maxLength}
        id="idea-input" style={{
        fontFamily: '"Europa", sans-serif',
        fontSize: '20px',
        color: 'grey'
      }}
        type="text"
        placeholder={this.props.placeholder}
      />

        <label style={{
          marginTop: '5px',
          fontFamily: '"Europa", sans-serif',
          fontSize: '11px',
          color: '#607d8b'
        }} htmlFor="idea-input">
          {this.props.label}
          { /* Show max length text only if maxLength prop passed. */
            this.props.maxLength ?
            <strong> {this.state.charsRemaining} characters left.</strong> :
            ""
          }
        </label>
    </div>
    );
  }
}

class Form extends Component {

  constructor(props, context){
    super(props, context);

    this.onInputChangeHandler = this.onInputChangeHandler.bind(this);
    this.onSubmitHandler = this.onSubmitHandler.bind(this);
    this.maxIdeaInputLength = this.props.maxIdeaInputLength;

    this.state = {
      charsRemaining: this.maxIdeaInputLength,
      showEmail: false
    }

  }

  onInputChangeHandler(remaining){
    this.setState({
      ...this.state,
      charsRemaining: remaining
    });

    if (this.props.onMaxLengthInputChange) this.props.onMaxLengthInputChange(this.state.charsRemaining);
  }

  onSubmitHandler(idea, email){

    console.log("Submitted idea", idea, "with email", email);

    this.setState({
      ...this.state,
      submitted: true
    });

    // Create form data component which will serialise the data before sending.
    var data = new FormData();
    data.append('Propsed Idea', idea);
    data.append('Sender Email', email);
    // Submit the idea.
    fetch("https://script.google.com/macros/s/AKfycbw57GFV1cqxdWYA1Ga_B_6ngLw2tciFiwErn8zIEWbCLHxrtCE/exec", {
      method: 'POST',
      body: data
    }).then(res => console.log(res.text().then(console.log)));


  }

  render(){

    const ideaInput = (<Input
        onEnter={(text) => this.setState({showEmail: true}) & (this.idea = text)}
        placeholder={"I have an idea..."}
        maxLength={150}
        label={"What would you like us to work on?"}
        onInputChange={this.onInputChangeHandler}
      />);

    const emailInput = (this.state.showEmail ? <Input
      onEnter={text => (this.email = text) && this.onSubmitHandler(this.idea, this.email)}
        autoFocus
        style={{
          marginTop: '15px',
        }}
        placeholder={"me@myself.com"}
        label={"How should we reach you?"}
      /> : "");

      const inputs = <div className="inputs-container">
        {ideaInput}
        {emailInput}
      </div>

      const submittedMessage = (<div className="form-submitted-message-container">
        <h2 style={{
          fontFamily: '"Europa", sans-serif',
          fontSize: '30px',
          color: 'grey'
        }}>Thanks for sharing your idea!</h2>
        <p style={{
          fontFamily: '"Europa", sans-serif',
          fontSize: '20px',
          color: 'rgba(255,255,255,0.4)'
        }}>We're really excited to read through your idea and see if we'd make a good match. We'll get back to you at the email you entered shortly.</p>
      </div>);

    return (
      <form onSubmit={e => e.preventDefault()} >

            {
              !this.state.submitted ?
              inputs
              : submittedMessage
            }

      </form>
    );
  }
}

class ContactPage extends Component {

  constructor(props, context){
    super(props, context);

    this.maxIdeaInputLength = 150;

    this.state = {
      charsRemaining: this.maxIdeaInputLength
    }

  }

  render(){
    return (
      <div
      ref={this.props.scrollRef}
      style={{
            minHeight: '100vh',
            width: '100vw',
            display: 'flex',
            alignItems: 'center',
            flexDirection: 'column',
            backgroundColor: 'rgb(17, 17, 17)',
            position: 'relative',
            // overflow: 'hidden'
          }} className="contact-page-container">

          <div style={{
            width: '80%',
            marginTop: 'auto',
            zIndex: 3
          }} className="contact-page-content-container">
            <h1 style={{
              fontFamily: '"Europa", sans-serif',
              fontSize: '50px',
              color: 'white',
              margin: '0'
            }}>What we believe.</h1>
            <p style={{
              margin: '0',
              marginTop: '20px',
              fontFamily: '"Europa", sans-serif',
              fontSize: '16px',
              color: 'grey'
            }}>
              Our vision is predicated on the need to help technology find this purpose by applying it in ways that solve problems and create enriching experiences. Its what we measure our success by.
              <br /><br />

              Amidst all the noise, what’s often most needed is a relentless passion for detail, and people who care enough to get <strong style={{
                fontWeight: 'normal',
                color: `hsla(
                  ${201 + ((360-201)/this.maxIdeaInputLength) * (this.maxIdeaInputLength-this.state.charsRemaining)},
                  ${100/this.maxIdeaInputLength * (this.maxIdeaInputLength - this.state.charsRemaining)*10 + '%'},
                  69%,
                  0.67)`
              }}>excited</strong> about your amazing ideas.
            </p>

            <p style={{
              fontFamily: '"Europa", sans-serif',
              fontSize: '40px',
              color: 'grey',
              width: '100%',
              textAlign: 'center'
            }}>...</p>

            <Form
              maxIdeaInputLength={this.maxIdeaInputLength}
              onMaxLengthInputChange={(remaining) => (this.setState({...this.state,charsRemaining: remaining}))}
            />

          </div>

          <Footer />

          {/* Svg background graphics. */}
          <div style={{
            width: '100vw',
            height: '100vh',
            position: 'absolute',
            overflow: 'hidden'
          }} className="footer-background-graphics-container">
            <svg style={{
              position: 'absolute',
              opacity: '0.45',
              height: '70%',
              left: '-2840px',
              bottom: '-22%',
            }} viewBox="0 0 6338 1391" height="1391px" width="6338px">
              <path d="M456.000,240.000 C817.956,505.612 1883.248,957.290 2810.000,734.000 C3736.752,510.710 4392.199,187.422 5015.000,66.000 C5637.801,-55.422 6326.000,67.000 6326.000,67.000 L6205.000,1186.000 L893.000,1379.000 L12.000,912.000 C12.000,912.000 94.044,-25.612 456.000,240.000 Z" fill="rgb(30, 30, 30)" opacity="0.502" stroke="rgb(255, 255, 255)" strokeWidth="10px"/>
            </svg>
          </div>


        </div>
      );
  }
}

export default ContactPage;
