/**
 * Logo components.
 *
 * Copyright @ Venture 2018
 */

import React, { Component } from 'react';
import LogoGlyphSrc from './assets/Venture_logo.svg';
import LogoWithTextSrc from './assets/Venture_logo_text.svg';

 class LogoGlyph extends Component {

   render(){
     return (
       <svg id={this.props.id ? this.props.id :  ""} ref={ref => this.props.svgRef ? this.props.svgRef(ref) : null} xmlns="http://www.w3.org/2000/svg"
       viewBox={this.props.viewBox ? this.props.viewBox : "0 0 528.77 525.5"}
       style={this.props.style}>
             <defs>
               <linearGradient id="linear-gradient" x1="101.9" y1="-45.28" x2="459.45" y2="380.83" gradientUnits="userSpaceOnUse"><stop offset="0" stopColor="#61ccfa"/>
               <stop offset="0.14" stopColor="#69c2fa"/><stop offset="0.37" stopColor="#80a8fa"/><stop offset="0.67" stopColor="#a57cf9"/>
               <stop offset="1" stopColor="#d544f9"/>
             </linearGradient>
           </defs>
           <path d="M460.1,3.61h-393A64.52,64.52,0,0,0,9.43,97l196.48,392.8a64.53,64.53,0,0,0,115.41,0L517.8,97A64.52,64.52,0,0,0,460.1,3.61Z"
           style={{
             "fill": "url(#linear-gradient)",
             ...this.props.pathStyle
           }}
           />
           {this.props.children}
       </svg>
     );
   }
 }

 // const LogoWithText = ({height, width, showText}) => (
 //   <div style={{
 //     height: height ? height : '50px',
 //     width: width ? width : '50px',
 //     backgroundImage: `url('${showText ? LogoWithTextSrc : LogoGlyphSrc}')`
 //   }}></div>
 // );

const LogoWithText = props => (
  <svg id="venture-logo-with-text" viewBox="0 0 922.33 172.49" {...props}>
    <defs>
      <linearGradient
        id="prefix__a"
        x1={31.46}
        y1={-11.99}
        x2={145.98}
        y2={124.48}
        gradientUnits="userSpaceOnUse"
      >
        <stop offset={0} stopColor="#61ccfa" />
        <stop offset={0.14} stopColor="#69c2fa" />
        <stop offset={0.37} stopColor="#80a8fa" />
        <stop offset={0.67} stopColor="#a57cf9" />
        <stop offset={1} stopColor="#d544f9" />
      </linearGradient>
    </defs>
    <title>{'Venture Logo Short'}</title>
    <text
      transform="rotate(.13 -60585.591 107137.007)"
      fontSize={203.568}
      fill="#fff"
      fontFamily="ProximaSoft-Regular,Proxima Soft"
      letterSpacing="-.02em"
    >
      {'venture'}
    </text>
    <path
      d="M146.19 3.66H20.33A20.67 20.67 0 0 0 1.84 33.57l62.94 125.81a20.66 20.66 0 0 0 37 0l62.89-125.81a20.66 20.66 0 0 0-18.48-29.91z"
      fill="url(#prefix__a)"
    />
  </svg>
);

 export { LogoWithText, LogoGlyph };
