/**
 * Slide out hamburger menu.
 *
 * Copyright @ Venture 2018.
 */

import React, { Component } from 'react';
import { LogoGlyph } from './Logo.js';
import Fade from 'react-reveal';
import { TweenMax, Power2 } from 'gsap';
import Scroll from 'react-scroll-to-element';


// const ExpandedHamburgerMenuBackground = ({svgRef, id}) => <svg
//   xmlns="http://www.w3.org/2000/svg"
//   width="2458px" height="2622px">
//   <path id={id} ref={ref => svgRef(ref)} fillRule="evenodd"  fill="rgb(147, 145, 250)"
//   d="M307.719,576.257 C625.899,25.209 1330.591,-163.594 1881.694,154.553 C2432.797,472.701 2621.618,1177.324 2303.439,1728.371 C1985.260,2279.419 900.137,2848.466 349.034,2530.318 C-202.068,2212.170 -10.460,1127.305 307.719,576.257 Z"/>
// </svg>


const VariableWidthHbar = () => <div className="hamburger-link-line"></div>

const HamburgerLink = ({text, number, navTo=null, collapseMenuHandler, style}) =>

<a className="nav-link" onClick={(e) => {

  e.preventDefault();
  navTo.current.scrollIntoView({behavior: 'smooth'});
  collapseMenuHandler();


}} href={navTo != null ? navTo : '#'} style={{
  'zIndex': '2',
  'fontFamily': '"Nunito", sans-serif',
  'outline': 'none',
  'textDecoration': 'none',
  'color': 'white',
  'display': 'flex',
  'width': '100%',
  ...style
}}>
{console.log(navTo)}
   <p className="hamburger-link-text">{text}</p>
   <VariableWidthHbar />
   <p className="hamburger-numbering-text">{number}</p>
</a>;

const HamburgerButton = ({onClickHandler}) => <button onClick={() => onClickHandler()} style={{
  'width': '27px',
  'height': '20px',
  // 'marginLeft': '40px',
  // 'marginBottom': '25px',
  position: 'absolute',
  top: '35px',
  right: '42px',
  'cursor': 'pointer',
  'zIndex': '3',
  }} className="reset low-opacity hover-fade-in">
  <svg style={{

  }}
  id="hamburger-menu-icon" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 45.5 33">
    <title>Hamburger Menu</title>
    <line id="hamburger-line-middle" x1="2.5" y1="16.17" x2="43" y2="16.17" style={{
      'fill':'none',
      'stroke':'#fff',
      'strokeLinecap':'round',
      'strokeLinejoin':'round',
      'strokeWidth':'5px',
 }}/>

  <line id="hamburger-line-bottom" x1="15" y1="30.5" x2="43" y2="30.5" style={{
    'fill':'none',
    'stroke':'#fff',
    'strokeLinecap':'round',
    'strokeLinejoin':'round',
    'strokeWidth':'5px',
  }}/>

  <line id="hamburger-line-top" x1="2.5" y1="2.5" x2="43" y2="2.5" style={{
    'strokeMiterlimit':'10',
    'fill':'none',
    'stroke':'#fff',
    'strokeLinecap':'round',
    'strokeLinejoin':'round',
    'strokeWidth':'5px',
  }}/>
  </svg>
</button>

class HamburgerNavLinks extends Component {

  constructor(props, context){
    super(props, context);
  }

  render(){

    // TODO: Add stagger animation with fade and slide in.

    return this.props.visible ? (
      <div className="hamburger-links-container">
      <Fade top cascade>
        <div>
        {this.props.links.map((item, index) =>
          <HamburgerLink
            collapseMenuHandler={this.props.collapseMenuHandler}
            key={index}
            text={item.name}
            number={"0"+index}
            style={{
              marginTop: index != 0 ? '20px' : '0px'
            }}
            navTo={item.link}
          />
        )}
        </div>
        </Fade>
      </div>
    ) : null;
  }
}


class HamburgerMenu extends Component {

 constructor(props, context){
   super(props, context);

   this.state = {
     active : false,
     linksVisible: false
   }

   // Why did we need to bind this to each function again?
   this.animateMenu = this.animateMenu.bind(this);
   this.onClickHandler = this.onClickHandler.bind(this);
   this.toggleLinks = this.toggleLinks.bind(this);
 }

 onClickHandler(state){

   this.animateMenu(state);

   // Change button state.
   this.setState({
       ...this.state,
       active: !this.state.active,
   });
   log(`Button state`, this.state.active);

 }

 toggleLinks(){
   this.setState({
     ...this.state,
     linksVisible: !this.state.linksVisible
   })
 }

 // Todo: Bind refs to the tweenmax functions so that onClickHandler can trigger
 // the appropriate animations. Otherwise, use Redux.
 animateMenu(state){

   return new Promise((resolve, reject) => {

     TweenMax.to(document.getElementById('hamburger-line-top'), 0.7, { css: {
       transform: `
         translateY(${!state.active ? '38px' : '0px'})
         rotate(${!state.active ? '-45deg' : '0deg'})
         translateX(${!state.active ? '10px' : '0px'})`},
       ease: Power2.easeOut
     });
     TweenMax.to(document.getElementById('hamburger-line-middle'), 0.7 , { css: {
       transform: `
       translateY(${!state.active ? '-37px' : '0px'})
       rotate(${!state.active ? '-45deg' : '0deg'})
       translateX(${!state.active ? '7.5px' : '0px'})`},
       ease: Power2.easeOut
     });

     TweenMax.to(document.getElementById('hamburger-line-bottom'), 1, {
       opacity: !state.active ? 0 : 1
     });

     // Expand the navigation bar to fit the expanded logo.
     // if (!this.state.active){
     //   log(`Opening nav`)
     //   TweenMax.to('nav', 0, {css: {
     //     height: !this.state.active ? '400px' : 'auto'
     //   }});
     // }

     TweenMax.to(this.logoRef, 1, {css: {
       transform: `translateX(${!state.active ? '-63px' : '0px'}) translateY(${!state.active ? '240px' : '0px'})`},
       ease: Power2.easeOut,
       onComplete: !this.state.active ? this.toggleLinks : null,
       // :  () => TweenMax.to('nav', 0, {css: {
       //    height: !this.state.active ? '400px' : 'auto'
       //  }})
       onStart: this.state.active ? this.toggleLinks:  null
     });

   });

 }
 // <HamburgerLink text={"Contact."} number={"02"} />
 // <HamburgerLink text={"Build."} number={"03"} />
 render(){
   return (
     <div className="hamburger-container" style={{
       'display': 'flex',
       'justifyContent': 'center',
       'alignItems': 'center',
       'width': '141px',
       'height': '120px',
       'marginLeft': 'auto',
     }}>

    <HamburgerButton onClickHandler={() => this.onClickHandler(this.state)} />

    <HamburgerNavLinks
    collapseMenuHandler={() => this.onClickHandler(this.state)}
    links={[
      {name: 'Build.', link: this.props.scrollToRefs[0]},
      {name: 'Our Babies.', link: this.props.scrollToRefs[1]},
      {name: 'Contact.', link: this.props.scrollToRefs[2]},
    ]}
    visible={this.state.linksVisible} />

   <LogoGlyph id={"hamburger-logo"} svgRef={(logoRef) => {
     this.logoRef = logoRef;
   }}  style={{
      'display': 'flex',
      'width': '900px',
      'position': 'absolute',
      'top': '-780px',
      'right': '-420px',
      'zIndex': '2',
      'height': '900px',
   }} />

   </div>
 );}
};

export default HamburgerMenu;

// Utility functions.
function log(...msg){
  console.log(`HAMBURGER MENU |`, ...msg);
}
