/**
 * Portfolio items.
 *
 *  @ Aaron Baw 2018
 */

import React, { Component } from 'react';
import Fade from 'react-reveal';

// class PortfolioItemExpanded extends Component {
//
// }

class PortfolioItem extends Component {
  constructor(props, context){
    super(props, context);
    this.state = {
      showCaption: false
    };

    this.showCaption = this.showCaption.bind(this);
    this.hideCaption = this.hideCaption.bind(this);

  }

  showCaption(){
    this.setState({
      ...this.state,
      showCaption: true,
    });
  }

  hideCaption(){
    this.setState({
      ...this.state,
      showCaption: false,
    });
  }

  render(){
    return (
      <a
      href={this.props.link}
      target="_blank"
      onMouseEnter={() => this.showCaption()}
      onMouseLeave={() => this.hideCaption()}
      className="grid-item"
      style={{
        backgroundColor: this.props.backgroundColor,
        position: 'relative',
        textDecoration: 'none'
      }}>

      <Fade top cascade when={!this.state.showCaption}>
        <div>
        <h1 className="grid-item-text">{this.props.name}</h1>
        </div>
      </Fade>

      <Fade bottom cascade when={this.state.showCaption}>
      <div style={{
        position: 'absolute',
        height: '100%',
        width: '80%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        // padding: '25px',
      }}>
        <p style={{
          fontSize: '14px',
          color: 'rgba(0, 0, 0, 0.3)',
          wordWrap: 'break-word',
          marginTop: 'auto',
          marginBottom: 'auto'
          // maxWidth: '350px'
        }} className="grid-item-text">{this.props.caption}</p>
        </div>
      </Fade>

    </a>);
  }

}

export { PortfolioItem };
